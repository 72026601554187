import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';

export function cart() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('cart.atc-page-load', ({ output = null }) => {

    if (output?.cart) {
      digitalData.cart = output.cart;
    }

    setAnalytics(digitalData);
  });
}