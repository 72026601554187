import { gql } from '@apollo/client';
import { useMemo } from 'react';
import { string } from './proptypes';

const walk = (object, ssr) => {
  if (object._client && ssr) {
    return null;
  }
  const objs = Object.keys(object).map((key) => {
    if (key === '_query') {
      return object[key];
    }
    if (key === '_skip' && object[key]._value) {
      const obj = object[key];
      return {
        [obj._value]: {
          type: 'Boolean',
          _skip: true,
          _value: `Boolean${typeof obj._defaultValue === 'undefined' ? '!' : ''}`,
          _defaultValue: object[key]._defaultValue
        }
      };
    }

    if (key === '_include' && object[key]._value) {

      const obj = object[key];
      return {
        [obj._value]: {
          type: 'Boolean',
          _include: true,
          _value: `Boolean${typeof obj._defaultValue === 'undefined' ? '!' : ''}`,
          _defaultValue: object[key]._defaultValue
        }
      };
    }

    if (typeof object[key] === 'object') {
      return walk(object[key], ssr);
    }
    return null;
  })
    .filter((val) => val)
    .flat();

  if (!objs.length) return {};

  return objs.reduce((acc, cur) => {
    return {
      ...acc,
      ...cur,
    };
  }, {});
};

export const getQueryArgs = ({ attributes = {}, asString = true, ssr = true }) => {
  const args = walk(attributes, ssr);

  if (!asString) return args;

  return Object.keys(args).map((key) => {
    const defaultValue = typeof args[key]._defaultValue !== 'undefined'
      ? `=${args[key]._defaultValue}`
      : '';
    if (args[key]._isArray) {
      const isRequired = args[key]._isRequired ? '!' : '';
      return `$${key}: [${args[key]._children._value}]${isRequired}`;
    }

    return `$${key}:${typeof args[key] === 'object' ? args[key]._value : args[key]}${defaultValue}`;
  }).join(', ');

};

export const getQueryParams = ({ attributes = {} }) => {

  return Object.keys(attributes).map((key) => {
    return `${key}:$${key}`;
  }).join(', ');

};

export const getQueryType = ({ attributes, name }) => {
  if (attributes[name]?._mutation) return 'mutation';
  return 'query';
};

export const getSubQuery = ({
  attributes = {},
  level = 1,
  ssr,
  clientResolver = false,
  skipDirective,
  includeDirective,
  inlineFragment = false,
  namedFragment = false,
  fragmentType = '',
  fragmentAlias = '',
  store = { namedFragmentArr: [], namedFragments: {} }
}) => {
  const spacing = new Array(level).fill('  ').join('');

  const query = Object.keys(attributes)
    .map((domain) => {
      if (attributes[domain]._client && ssr) return '';

      // eslint-disable-next-line max-len
      if (attributes[domain].type === 'Object' && attributes[domain]._isArray && attributes[domain]?._children?.type !== 'String') {
        return getSubQuery({
          attributes: { [domain]: attributes[domain]._children },
          ssr,
          store
        }).query;
      }

      let queryParam = domain;
      if (attributes[domain]._query) {
        const params = getQueryParams({ attributes: attributes[domain]._query });
        if (params) {
          queryParam = `${domain}(${params})`;
        }
      }

      let skipDirectiveString = skipDirective
        ? `@skip(if:$${skipDirective._value})`
        : '';

      let includeDirectiveString = includeDirective
        ? `@include(if:$${includeDirective._value})`
        : '';

      // array response with just a string
      if (attributes[domain]?._children?.type === 'String' && attributes[domain]._isArray) {
        // eslint-disable-next-line max-len
        return `${spacing} ${queryParam} ${skipDirectiveString} ${includeDirectiveString} ${clientResolver ? '@client' : ''}`;
      }

      // just a string response
      if (attributes[domain]?.type === 'String') {
        // eslint-disable-next-line max-len
        return `${spacing} ${queryParam} ${skipDirectiveString} ${includeDirectiveString} ${clientResolver ? '@client' : ''}`;
      }

      const fragmentName = fragmentAlias !== '' ? fragmentAlias : `Core${domain}Fields`;
      const selector = inlineFragment
        ? `... on ${domain} {`
        // eslint-disable-next-line max-len
        : `${spacing} ${queryParam} ${skipDirectiveString} ${includeDirectiveString} ${clientResolver ? '@client' : ''} {`;

      const innerBody = `${Object.keys(attributes[domain]).map((attribute) => {
        if (attribute === '__typename') {
          return attribute;
        }
        if (/^_/.test(attribute)) {
          return '';
        }

        const suffix = attributes[domain][attribute]._clientResolver
          ? ' @client'
          : '';

        // mixed prop types that get merged must ignore _children
        /*
        pricing: shapeType({
          value: numberType({ float: true })
        }),
        pricing: params(storeId).shape({
          value: numberType({ float: true })
        }),
          */
        if (!attributes[domain][attribute]._isPropType) {
          if (attributes[domain][attribute].type === 'Object' && !attributes[domain][attribute]._query) {
            // primiative as a propType should not render children in query
            if (attributes[domain][attribute]._children._isPropType) {
              return spacing + attribute + suffix;
            }

            // arrays that are client only should return empty
            if (attributes[domain][attribute]._client && ssr) return '';

            return getSubQuery({
              clientResolver: attributes[domain][attribute]._clientResolver,
              skipDirective: attributes[domain][attribute]._skip,
              includeDirective: attributes[domain][attribute]._include,
              attributes: { [attribute]: attributes[domain][attribute]._children },
              inlineFragment: attributes[domain][attribute]._inlineFragment,
              namedFragment: attributes[domain][attribute]._namedFragment,
              fragmentType: attributes[domain][attribute]?._fragmentType || '',
              fragmentAlias: attributes[domain][attribute]?._fragmentAlias || '',
              ssr,
              level: level + 1,
              store
            }).query;
          }
          if (attributes[domain][attribute]._query && attributes[domain][attribute].type) {
            // eslint-disable-next-line max-len
            console.warn(`Warning: a merge conflict has occured between data sources at ${domain}/${attribute}. The children and type have been removed`, attributes[domain][attribute]);
            // eslint-disable-next-line
            delete attributes[domain][attribute]._children;
            // eslint-disable-next-line
            delete attributes[domain][attribute].type;
          }
          return getSubQuery({
            attributes: { [attribute]: attributes[domain][attribute] },
            skipDirective: attributes[domain][attribute]._skip,
            includeDirective: attributes[domain][attribute]._include,
            inlineFragment: attributes[domain][attribute]._inlineFragment,
            namedFragment: attributes[domain][attribute]._namedFragment,
            fragmentType: attributes[domain][attribute]?._fragmentType || '',
            fragmentAlias: attributes[domain][attribute]?._fragmentAlias || '',
            ssr,
            store
          }).query;
        }

        // reset includeDirectiveString
        includeDirectiveString = '';
        skipDirectiveString = '';
        // PropTypes could also have skips and include
        if (attributes[domain][attribute]._skip) {
          skipDirectiveString = ` @skip(if:$${attributes[domain][attribute]._skip._value}) `;
        }

        if (attributes[domain][attribute]._include) {
          // `@include(if:$${includeDirective._value})`
          includeDirectiveString = ` @include(if:$${attributes[domain][attribute]._include._value}) `;
        }

        return spacing + attribute + skipDirectiveString + includeDirectiveString + suffix;
      }).filter((item) => {
        return item && item !== '';
      })
        .join('\n' + spacing)}`;

      /* eslint-disable no-param-reassign */
      if (namedFragment) {
        if (!store.namedFragments[fragmentType + fragmentName]) {
          store.namedFragments[fragmentType + fragmentName] = `
fragment ${fragmentName} on ${fragmentType} {
  ${innerBody}
}
`;
          store.namedFragmentArr.push(store.namedFragments[fragmentType + fragmentName]);
        }
      }
      /* eslint-enable no-param-reassign */
      return `
${selector}
  ${namedFragment ? '...' + fragmentName : innerBody}
${spacing}}`;
    }).join('');

  return { query, store };
};

export const getQueryFromAttributes = ({ name, attributes = {}, level = 1, ssr = true }) => {
  // if (attributes.product?._query) {
  //   // eslint-disable-next-line no-param-reassign
  //   attributes.product._query.dataSource = string();
  // }
  const { query, store } = getSubQuery({
    attributes,
    level,
    ssr
  });

  const args = getQueryArgs({ attributes, ssr });
  const queryType = getQueryType({ attributes, name });

  const argsWrapper = args
    ? `(${args})`
    : '';

  return gql`
${queryType} ${name}${argsWrapper} {
  ${query}
}
${store.namedFragmentArr.map((fragment) => {
    return fragment;
  })}`;
};
