/* istanbul ignore file */
import { acceptInvitePage } from './pages/acceptInvite/acceptInvitePage';
import { articleExperience } from './pages/article/article';
import { articleListingPage } from './pages/articleListingPage/articleListingPage';
import { bathLandingPage } from './pages/bathLandingPage/bathLandingPage';
import { browseAndCategory } from './pages/browseAndCategory/browseAndCategory';
import { bip } from './pages/bip/bip';
import { buyItAgain } from './pages/buyItAgain/buyItAgain';
import { cart } from './pages/cart/cart';
import { checkout } from './pages/checkout/checkout';
import { collections } from './pages/collections/collections';
import { colorWall } from './pages/colorWall/colorWall';
import { communicationPreferences } from './pages/communicationPreferences/communicationPreferences';
import { compare } from './pages/compare/compare';
import { content } from './pages/content/content';
import { creditCenter } from './pages/creditCenter/creditCenter';
import { customerLoyalty } from './pages/customerLoyalty/customerLoyalty';
import { exchange } from './pages/exchange/exchange';
import { exerciseMyPrivacyRights } from './pages/exerciseMyPrivacyRights/exerciseMyPrivacyRights';
import { giftcards } from './pages/giftcards/giftcards';
import { landingPage } from './pages/landingPage/landingPage';
import { localAd } from './pages/localAd/localAd';
import { militaryDiscount } from './pages/militaryDiscount/militaryDiscount';
import { myCart } from './pages/my-cart/my-cart';
import { myHomepage } from './pages/myHomepage/myHomepage';
import { myList } from './pages/myList/myList';
import { myPreferenceCenter } from './pages/myPreferenceCenter/myPreferenceCenter';
import { optOut } from './pages/optOut/optOut';
import { orderConfirmation } from './pages/orderConfirmation/orderConfirmation';
import { packages } from './pages/packages/packages';
import { paintPurchaseHistoryPage } from './pages/paintPurchaseHistory/paintPurchaseHistory';
import { pathToProNetwork } from './pages/pathToProNetwork/pathToProNetwork';
import { pep } from './pages/pep/pep';
import { pip } from './pages/pip/pip';
import { poJobsPage } from './pages/poJob/poJob';
import { projectCalculators } from './pages/projectCalculators/projectCalculators';
import { promotions } from './pages/promotions/promotions';
import { rebateCenter } from './pages/rebate-center/rebate-center';
import { search } from './pages/search/search';
import { selfService } from './pages/self-service/self-service';
import { shopByRoom } from './pages/shopByRoom/shopByRoom';
import { specialityContent } from './pages/specialityContent/specialityContent';
import { storeFinder } from './pages/store-finder/store-finder';
import { vendorCollections } from './pages/vendorCollections/vendorCollections';
import { workshops } from './pages/workshops/workshops';
import { proAllowancePage } from './pages/pro-allowance/proAllowance';
import { projects } from './pages/projects/projects';

export const page = () => {
  // do not add SeoExperience() here - it's in ThdAnalytics.js for loading prioritization

  // alphabetized experience events
  acceptInvitePage();
  articleExperience();
  articleListingPage();
  bathLandingPage();
  browseAndCategory();
  buyItAgain();
  bip();
  cart();
  checkout();
  collections();
  colorWall();
  communicationPreferences();
  compare();
  content();
  creditCenter();
  customerLoyalty();
  exchange();
  exerciseMyPrivacyRights();
  giftcards();
  landingPage();
  localAd();
  militaryDiscount();
  myCart();
  myHomepage();
  myList();
  myPreferenceCenter();
  optOut();
  orderConfirmation();
  packages();
  paintPurchaseHistoryPage();
  pathToProNetwork();
  pep();
  pip();
  poJobsPage();
  projectCalculators();
  promotions();
  rebateCenter();
  search();
  selfService();
  shopByRoom();
  specialityContent();
  storeFinder();
  vendorCollections();
  workshops();
  proAllowancePage();
  projects();
};
