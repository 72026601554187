import digitalData from 'digital-data';
import { setState } from '../../../analytics-state';
import { setAnalytics, isDuplicatePageError, getContentProducts } from '../../../thd-analytics-utils';
import {
  ACCOUNT,
  PROJECTS_SUMMARY,
  PROJECTS_DETAILS,
  PROJECTS_DETAILS_OVERVIEW,
  PROJECTS_DETAILS_MATERIALS
} from '../../../constants';

export function projects() {
//   LIFE_CYCLE_EVENT_BUS.lifeCycle.on('list-details.page-load', (output) => {
//     const pageType = MY_LIST_DETAILS_PAGE_TYPE;
//     setState({ pageType });
//     digitalData.page = {
//       pageInfo: {
//         pageName: MY_LIST_DETAILS,
//         pageType,
//       },
//       category: {
//         primaryCategory: MY_LIST_DETAILS,
//         subCategory1: MY_LIST_DETAILS,
//         subCategory2: MY_LIST_DETAILS,
//       }
//     };
//     setAnalytics(digitalData);
//   });

  // 7.51.1 Projects Page
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('projects-summary.page-load', ({ output }) => {
    const pageType = 'maml: projects';
    const view = output?.view || '';
    setState({ pageType });
    digitalData.page = {
      pageInfo: {
        pageName: `${PROJECTS_SUMMARY}>${view}`,
        pageType,
      },
      category: {
        primaryCategory: ACCOUNT,
        subCategory1: PROJECTS_SUMMARY,
        subCategory2: PROJECTS_SUMMARY,
      }
    };
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('projects.page-error', ({ output }) => {
    const {
      type = 'error',
      displayType = 'page',
      pageSection = '',
      message = '',
      code = ''
    } = output || {};

    const pageError = {
      type,
      displayType,
      pageSection,
      message,
      code
    };
    digitalData.page = digitalData.page || {};
    digitalData.page.error = digitalData.page.error || [];
    if (!isDuplicatePageError(digitalData, pageError)) {
      digitalData.page.error.push(pageError);
    }
  });

  // 7.51.14 Projects Details
  LIFE_CYCLE_EVENT_BUS.on('projects-details.page-load', ({ output }) => {
    const pageType = 'maml: projects';
    const view = output?.view || '';
    setState({ pageType });
    digitalData.page = {
      pageInfo: {
        pageName: `${PROJECTS_DETAILS}>${view}`,
        pageType,
      },
      category: {
        primaryCategory: ACCOUNT,
        subCategory1: PROJECTS_SUMMARY,
        subCategory2: PROJECTS_SUMMARY,
      }
    };
    setAnalytics(digitalData);
  })
}